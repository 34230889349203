import { Box, Checkbox, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Typography } from "@mui/material";
import { fontSize } from "@mui/system";
import React, { Fragment, useState, useEffect } from "react";

import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import lineImg from "../../assets/images/line-header-sec.png";
import { DataGrid } from "@mui/x-data-grid";
import StarIcon from "@mui/icons-material/Star";
import DeleteIcon from "@mui/icons-material/Delete";
import DoneIcon from "@mui/icons-material/Done";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import { getCookiesList } from "../../utils/cookiesHelper";
import { call_api_function } from "../../utils/verifyAPICalls";
import AlertModal from "../@common/Modal/AlertModal";
import ConfirmationModal from "../@common/Modal/ConfirmationModal";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import RenderCellExpander from "../@common/RenderCellExpander";
function AssignCoupons(props) {
  const { t } = useTranslation();
  const [cookieList, setCookieList] = useState([]);
  const [sbCupons, setSbCupons] = useState(0);
  const [cbCupons, setCbCupons] = useState(0);
  const [companiesCupons, setCompaniesCupons] = useState(0);
  const [selectedRows, setSelectedRows] = useState([]);
  const [userList, setUserList] = useState([]);
  const [adminUserList, setAdminUserList] = useState([]);
  const [showAlert, setShowAlert] = useState(false); // Add a state to control the visibility of the alert
  const [showtext, setShowtext] = useState("");
  const [showConfirmationModal, setshowConfirmationModal] = useState(false); // Add a state to control the visibility of the alert
  const [showConfirmationtext, setShowConfirmationtext] = useState("");
  const [showimg, setimg] = useState(false);
  const [sourceEmail, setSourceEmail] = useState(null);
  const [loggedInUserEmail, setLoggedInUserEmail] = useState(null);
  const [noOfCoupons, setNoOfCoupons] = useState({
    CB: { disponible: 0, total: 0 },
    SB: { disponible: 0, total: 0 },
    Companies: { disponible: 0, total: 0 },
  });
  const userInfo = Cookies.get("userInfo");
  const userEmail = userInfo.split(",")[3];
  const UserCoupons = async () => {
    try {
      const formdata = new FormData();
      formdata.append("email", userEmail);
      const response = await call_api_function(
        process.env.REACT_APP_BASE_URL2 + "perfil/checar_cupones",
        formdata
      );

      if (response.status === 200) {
        setNoOfCoupons(response.data);
      } else {
        setNoOfCoupons({
          CB: { disponible: 0, total: 0 },
          SB: { disponible: 0, total: 0 },
          Companies: { disponible: 0, total: 0 },
        });
      }
    } catch (error) {
      setShowAlert(true);
      setShowtext("Error:", error);
    }
  };
  useEffect(() => {
    UserCoupons(userEmail);
  }, []);
  useEffect(() => {
    const element1 = document.getElementById("SB");
    const element2 = document.getElementById("CB");
    const element3 = document.getElementById("Companies");
    if (element1) {
      element1.innerText = noOfCoupons.SB?.disponible + " / " + noOfCoupons.SB?.total;
    }
    if (element2) {
      element2.innerText = noOfCoupons.CB?.disponible + " / " + noOfCoupons.CB?.total;
    }
    if (element3) {
      element3.innerText = noOfCoupons.Companies?.disponible + " / " + noOfCoupons.Companies?.total;
    }
  }, [noOfCoupons]);

  function isValidEmail(email) {
    const emailRegex = /\S+@\S+\.\S+/;
    return emailRegex.test(email);
  }

  useEffect(() => {
    const userCookiesList = getCookiesList("userInfo");
    if (userCookiesList.length > 0) {
      setCookieList(userCookiesList);
    }
    const email = userCookiesList.find(item => isValidEmail(item));
    if (email) {
      setLoggedInUserEmail(email);
      setSourceEmail(email);
    }
  }, []);
  const handleCloseAlert = () => {
    setShowAlert(false);
    setimg(false);
  };
  useEffect(() => {
    fetchUserList(cookieList);
  }, [cookieList]);

  const handleSelectionChange = (selection) => {
    alert(selection.selectionModel);
    setSelectedRows(selection.selectionModel);
  };

  const filterUserList = (data) => {
    const fullUsersList = data;
    const updatedEmailsAdmins = [];
    const updatedEmailsPendingAuth = [];
    const updatedEmails = [];
    const updatedEmailsNR = [];

    for (const user of fullUsersList) {
      const [email, isAdmin, role, removed] = user;
      if (isAdmin) {
        if (role == "admin") {
          updatedEmailsAdmins.push(user);
        } else if (!removed) {
          updatedEmailsPendingAuth.push(user);
        } else {
          updatedEmails.push(user);
        }
      } else if (isAdmin == "removed") {
        const index = fullUsersList.findIndex((user) => user[0] === email);
        if (index !== -1) {
          fullUsersList.splice(index, 1);
        }
      } else {
        updatedEmailsNR.push(user);
      }
    }

    return {
      fullUsersList,
      updatedEmailsAdmins,
      updatedEmailsPendingAuth,
      updatedEmails,
      updatedEmailsNR,
    };
  };

  const fetchUserList = async (cookList) => {
    if (cookList.length > 0 && cookList[3] != undefined) {
      const users = {
        0: [cookList[3]],
      };
      const formData = new FormData();
      formData.append("0", cookList[3]);
      try {
        const response = await call_api_function(
          process.env.REACT_APP_BASE_URL2 + "perfil/lista_asignados",
          formData
        );
        if (response.data == "error bd" || response.data?.length < 0) {
          setShowAlert(true);
          setShowtext(t("thanks.check"));
        } else if (response.data == "no data") {
          setShowAlert(true);
          setShowtext("No tienes,usuarios agregados");
        } else {
          const filteredUsers = filterUserList(response.data);
          // set differ users state when necessary
          setUserList(filteredUsers["fullUsersList"]);
          setAdminUserList(filteredUsers["updatedEmailsAdmins"]);
        }
      } catch (e) {
        console.log(e);
      }
    }
  };
  const columns = [
    {
      field: "email",
      headerName: t("AdministratorUsers.rightContentTablesHeaders1"),
      editable: true,
      flex: 1,
      renderCell: RenderCellExpander,
    },
    {
      // field: "action",
      // headerName: t("AdministratorUsers.Actions"),
      // width: 110,

      //   editable: true,
      sortable: false,
      renderCell: (params) => (
        <>
          {params.row.isAdmin ? (
            <Button
              sx={{
                border: "solid 1px rgba(27, 32, 44, 0.2)",
                borderRadius: 50,
                p: "7px",
                height: "40px",
                width: "40px",
                minWidth: "40px",
                outline: "none",
                cursor: "default",
                marginLeft: "10px",
              }}
            >
              <StarIcon sx={{ color: "#FAD803" }} />
            </Button>
          ) : null}
        </>
      ),
    },
  ];

  const rows = userList
    .filter(
      (user) =>
        (user[2] === "admin" && user[1] === true) ||
        (user[2] === "usuario" && user[3] === true)
    )
    .map((user, index) => ({
      id: index,
      email: user[0],
      isAdmin: user[2] === "admin",
    }));

  const loggedInUserExists = rows.some((user) => user.email === loggedInUserEmail);
  if (!loggedInUserExists) {
    rows.push({
      id: rows.length + 1,
      email: loggedInUserEmail,
    });
  }

  const removeHandlerSB = () => {
    setSbCupons(Math.max(sbCupons - 1, 0));
  };
  const removeHandlerCB = () => {
    setCbCupons(Math.max(cbCupons - 1, 0));
  };
  const removeHandlerCompanies = () => {
    setCompaniesCupons(Math.max(companiesCupons - 1, 0));
  };

  const continueHandler = async () => {
    setshowConfirmationModal(false);
    setimg(false);
    const selectedEmails = rows
      .filter((row) => selectedRows.includes(row.id))
      .map((row) => row.email);

    if (selectedEmails.length > 0) {
      const payloadList = [
        sourceEmail ? sourceEmail : cookieList[3],
        cbCupons,
        sbCupons,
        companiesCupons,
        ...selectedEmails,
      ];

      let formData = new FormData();
      payloadList.forEach((item, index) => {
        formData.append(index.toString(), item);
      });

      try {
        const response = await call_api_function(
          process.env.REACT_APP_BASE_URL2 + "perfil/asignar_cupones",
          formData
        );

        if (response.data === "no data") {
          setShowAlert(true);
          setShowtext("no data");
        } else if (response.data === "error bd") {
          setShowAlert(true);
          setShowtext("error bd");
        } else if (response.data === "cant assign to self") {
          setShowAlert(true);

          localStorage.getItem("selectedLanguage") === "en"
            ? setShowtext("Can't assign coupons to self")
            : setShowtext("No se puede asignar cupones a uno mismo");
        } else if (response.data === "cupones insuficientes") {
          setShowAlert(true);

          localStorage.getItem("selectedLanguage") === "en"
            ? setShowtext("Insufficient coupons")
            : setShowtext("Cupones insuficientes");
        } else if (response.data === null) {
          setShowAlert(true);
          setShowtext("check connection");
        } else {
          var cupones = response.data;
          setimg(true);
          setShowAlert(true);
          setShowtext(t("CouponsManager.popUpSucess"));
          props.getcoupan(); // Call the parent function
          UserCoupons(userEmail);
        }
      } catch (e) {
        console.log(e);
      }
    } else {
      setShowAlert(true);
      setShowtext(t("thanks.select"));
    }
  };

  const addHandlerCB = () => {
    setCbCupons(cbCupons + 1);
  };
  const addHandlerSB = () => {
    setSbCupons(sbCupons + 1);
  };
  const addHandlerCompanies = () => {
    setCompaniesCupons(companiesCupons + 1);
  };

  useEffect(() => {
    if (rows.length > 0) {
      let isLoggedInUserExist = rows.find((user, i) => user.email == loggedInUserEmail);
      if (isLoggedInUserExist && sourceEmail) {
        setSourceEmail(isLoggedInUserExist.email)
      }
    }
  }, [])

  const handleCheckboxChange = (email) => {
    if (email) {
      setSourceEmail(email === sourceEmail ? '' : email);
    }
  };

  const handleRadioToggle = (event) => {
    const value = event.target.value;
    if (value) {
      setSourceEmail(value === sourceEmail ? '' : value);
    }
  };

  const assignHandler = () => {
    setshowConfirmationModal(true);
    setShowConfirmationtext(t("TransferCoupons.confimationText"));
    setimg(true);
  }

  const handleCloseConfirmationModal = () => {
    setshowConfirmationModal(false);
    setimg(false);
  };

  return (
    <>

      {showAlert && (
        <AlertModal
          text={showtext}
          onClose={handleCloseAlert}
          thanks={showimg}
        />
      )}

      {showConfirmationModal && (
        <ConfirmationModal
          text={showConfirmationtext}
          onClose={handleCloseConfirmationModal}
          onAccept={continueHandler}
          thanks={showimg}
        />
      )}

      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          backgroundColor: "#ffffff",
          borderRadius: 2,
          px: 4,
          py: 3,
        }}
      >
        <Typography
          sx={{
            // color: "neutral.900",
            fontFamily: "Gilroy-SemiBold",
            fontSize: 22,
            textAlign: "center",
            color: "#1C212D"
          }}
        >
          {t("CouponsManager.leftContentTittle")}
        </Typography>



        {rows.length > 0 &&
          <Box sx={{ margin: "12px 0" }}>
            <Typography
              sx={{
                height: "56px",
                fontSize: 18,
                fontFamily: "Gilroy-SemiBold",
                background: "#ff8d28",
                margin: 0,
                padding: "12px",
                borderRadius: "8px 8px 0 0",
                color: "#fff"
              }}
            >
              {t("CouponsManager.sourcesEmail")}
            </Typography>

            <Box sx={{
              width: 1,
              border: "solid 1px #f4f4f4",
              padding: "14px",
              boxShadow: "0px 25px 60px #00000008",
              borderRadius: "0 0 8px 8px"
            }}>
              <FormControl>
                {/* <RadioGroup
                  key={sourceEmail}
                  defaultValue={sourceEmail}
                  onClick={handleRadioToggle}
                >
                  <>
                    <FormControlLabel value={loggedInUserEmail}
                      control={<Radio />} label={loggedInUserEmail}
                      sx={{
                        "& span.MuiTypography-root.MuiTypography-body1.MuiFormControlLabel-label.css-e3focy-MuiTypography-root": {
                          color: "#1C212D"
                        }
                      }}
                    />

                    {rows?.map((user, index) => {
                      if (loggedInUserEmail == user.email) {
                        return false;
                       }
                      return <FormControlLabel
                        value={user.email}
                        control={<Radio />}
                        label={user.email}
                        sx={{
                          "& span.MuiTypography-root.MuiTypography-body1.MuiFormControlLabel-label.css-e3focy-MuiTypography-root": {
                            color: "#1C212D"
                          }
                        }} />
                    })
                    }
                  </>
                </RadioGroup> */}


                {rows.map((user, index) => (
                  <FormControlLabel
                    key={index}
                    control={
                      <Checkbox
                        checked={sourceEmail === user.email}
                        onChange={() => handleCheckboxChange(user.email)}
                      />
                    }
                    label={user.email}
                    sx={{
                      "& span.MuiTypography-root.MuiTypography-body1.MuiFormControlLabel-label.css-e3focy-MuiTypography-root": {
                        color: "#1C212D",
                        fontSize: 18,
                      },
                    }}
                  />
                ))}
              </FormControl>
            </Box>
          </Box>
        }

        <Box sx={{ width: "100%", mt: 1.8 }}>
          <DataGrid
            rows={rows}
            columns={columns}
            onRowSelectionModelChange={(newRowSelectionModel) => {
              setSelectedRows(newRowSelectionModel);
            }}
            isCellEditable={() => false}
            autoHeight // Disable field editing
            sx={{
              "& .css-ubja00-MuiDataGrid-root .MuiDataGrid-columnHeader:not(.MuiDataGrid-columnHeader--sorted):hover .MuiDataGrid-sortIcon ": {
                opacity: "1"
              },

              "& svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeSmall.MuiDataGrid-sortIcon.css-ptiqhd-MuiSvgIcon-root": {
                color: "#fff"
              },
              " & .css-yrdy0g-MuiDataGrid-columnHeaderRow :nth-child(1 of svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-i4bv87-MuiSvgIcon-root)": {
                fill: "#fff"
              },
              "& .MuiDataGrid-columnSeparator.MuiDataGrid-columnSeparator--sideRight  svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-i4bv87-MuiSvgIcon-root": {
                display: "none"
              },
              border: "solid 1px #f4f4f4",
              "& .MuiDataGrid-columnHeader": {
                backgroundColor: "#ff8d28",
                borderTopRightRadius: 4,
              },
              "& .MuiDataGrid-columnHeaderTitle": {
                fontSize: 18,
                color: "#fff",
                fontFamily: "Gilroy-SemiBold"
              },
              "& .MuiDataGrid-cellContent": {
                fontSize: 18,
                color: "#fff",
                fontFamily: "Gilroy-Medium"
              },
              "& .css-wop1k0-MuiDataGrid-footerContainer": {
                display: "none",
              },
              "& .MuiDataGrid-withBorderColor": {
                borderColor: "#f4f4f4 !important",
              },
              "& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-cell:focus": {
                outline: "none !important", // Remove the outline
              },
              "& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-cell:focus-within":
              {
                outline: "none !important",
              },
              "& .MuiDataGrid-selectedRowCount": {
                display: "none !important",
              },
              width: "100%",
            }}
            initialState={{
              pagination: false, // Disable pagination
            }}
            checkboxSelection
            disableRowSelectionOnClick
            hideFooterPagination={true}
            disableColumnMenu={true} // disable the filter functionality
          />
        </Box>
        <Box sx={{ textAlign: "center", my: 4 }}>
          <Typography
            sx={{
              color: "text.opacity",
              fontSize: 18,
              fontFamily: "Gilroy-Medium",
            }}
          >
            {t("CouponsManager.leftContentSubTittle")}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            justifyContent: "space-around",
            alignItems: "center",
            gap: 1,
          }}
        >
          <Box>
            <Typography
              sx={{
                width: "100%",
                textAlign: "center",
                fontSize: 18,
                fontFamily: "Gilroy-Medium",
                color: "neutral.900",
                mb: 1,
              }}
            >
              {t("CouponsManager.leftContentAdd")}
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                border: "solid 1px #f4f4f4",
                borderRadius: 1,
              }}
            >
              <Button
                onClick={removeHandlerCB}
                sx={{
                  color: "neutral.900",
                  fontSize: 20,
                  fontFamily: "Gilroy-SemiBold",
                  p: 0,
                  minWidth: 50,
                  height: 50,
                }}
              >
                <RemoveIcon sx={{ color: "neutral.900", fontSize: 26 }} />
              </Button>
              <Box sx={{ display: "flex" }}>
                <img src={lineImg} height={30} alt="line" />
              </Box>
              <input
                type="text"
                onChange={(e) => {
                  const newValue = e.target.value.replace(/\D/, "") || 0;
                  setCbCupons(Math.max(parseInt(newValue), 0));
                }}
                value={cbCupons}
                style={{
                  color: "primary.main",
                  fontSize: 16,
                  fontFamily: "Gilroy-SemiBold",
                  width: 70,
                  textAlign: "center",
                  height: 50,
                  border: "none",
                  outline: "none",
                  color: "#4165F6",
                }}
              />

              <Box sx={{ display: "flex" }}>
                <img src={lineImg} height={30} alt="line" />
              </Box>
              <Button
                onClick={addHandlerCB}
                sx={{
                  color: "neutral.900",
                  fontSize: 20,
                  fontFamily: "Gilroy-SemiBold",
                  p: 0,
                  minWidth: 50,
                  height: 50,
                }}
              >
                <AddIcon sx={{ color: "neutral.900", fontSize: 26 }} />
              </Button>
            </Box>
          </Box>

          <Box>
            <Typography
              sx={{
                width: "100%",
                textAlign: "center",
                fontSize: 18,
                fontFamily: "Gilroy-Medium",
                color: "neutral.900",
                mb: 1,
              }}
            >
              {t("CouponsManager.leftContentAddW")}
            </Typography>
            <Box
              sx={{
                mt: 0,
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                border: "solid 1px #f4f4f4",
                borderRadius: 1,
              }}
            >
              <Button
                onClick={removeHandlerSB}
                sx={{
                  color: "neutral.900",
                  fontSize: 20,
                  fontFamily: "Gilroy-SemiBold",
                  p: 0,
                  minWidth: 50,
                  height: 50,
                }}
              >
                <RemoveIcon sx={{ color: "neutral.900", fontSize: 26 }} />
              </Button>
              <Box sx={{ display: "flex" }}>
                <img src={lineImg} height={30} alt="line" />
              </Box>
              <input
                type="text"
                onChange={(e) => {
                  const newValue = e.target.value.replace(/\D/, "") || 0;
                  setSbCupons(Math.max(parseInt(newValue), 0));
                }}
                value={sbCupons}
                style={{
                  color: "primary.main",
                  fontSize: 16,
                  fontFamily: "Gilroy-SemiBold",
                  width: 70,
                  textAlign: "center",
                  height: 50,
                  border: "none",
                  outline: "none",
                  color: "#4165F6",
                }}
              />
              <Box sx={{ display: "flex" }}>
                <img src={lineImg} height={30} alt="line" />
              </Box>
              <Button
                onClick={addHandlerSB}
                sx={{
                  color: "neutral.900",
                  fontSize: 20,
                  fontFamily: "Gilroy-SemiBold",
                  p: 0,
                  minWidth: 50,
                  height: 50,
                }}
              >
                <AddIcon sx={{ color: "neutral.900", fontSize: 26 }} />
              </Button>
            </Box>
          </Box>

          <Box>
            <Typography
              sx={{
                width: "100%",
                textAlign: "center",
                fontSize: 18,
                fontFamily: "Gilroy-Medium",
                color: "neutral.900",
                mb: 1,
              }}
            >
              {t("CouponsManager.leftContentAddC")}
            </Typography>
            <Box
              sx={{
                mt: 0,
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                border: "solid 1px #f4f4f4",
                borderRadius: 1,
              }}
            >
              <Button
                onClick={removeHandlerCompanies}
                sx={{
                  color: "neutral.900",
                  fontSize: 20,
                  fontFamily: "Gilroy-SemiBold",
                  p: 0,
                  minWidth: 50,
                  height: 50,
                }}
              >
                <RemoveIcon sx={{ color: "neutral.900", fontSize: 26 }} />
              </Button>
              <Box sx={{ display: "flex" }}>
                <img src={lineImg} height={30} alt="line" />
              </Box>
              <input
                type="text"
                onChange={(e) => {
                  const newValue = e.target.value.replace(/\D/, "") || 0;
                  setCompaniesCupons(Math.max(parseInt(newValue), 0));
                }}
                value={companiesCupons}
                style={{
                  color: "primary.main",
                  fontSize: 16,
                  fontFamily: "Gilroy-SemiBold",
                  width: 70,
                  textAlign: "center",
                  height: 50,
                  border: "none",
                  outline: "none",
                  color: "#4165F6",
                }}
              />
              <Box sx={{ display: "flex" }}>
                <img src={lineImg} height={30} alt="line" />
              </Box>
              <Button
                onClick={addHandlerCompanies}
                sx={{
                  color: "neutral.900",
                  fontSize: 20,
                  fontFamily: "Gilroy-SemiBold",
                  p: 0,
                  minWidth: 50,
                  height: 50,
                }}
              >
                <AddIcon sx={{ color: "neutral.900", fontSize: 26 }} />
              </Button>
            </Box>
          </Box>
        </Box>
        <Box sx={{ mt: 4, display: "flex", justifyContent: "center" }}>
          <Button
            onClick={assignHandler}
            disabled={cbCupons === 0 && sbCupons === 0 && companiesCupons === 0}
            variant="contained"
            sx={{
              backgroundColor: "primary.main",
              py: 1,
              px: { xs: 4, sm: 10 },
              fontSize: "18px",
              fontFamily: "Gilroy-Medium",
              textTransform: "capitalize",
            }}
          >
            {t("CouponsManager.mainButton")}
          </Button>
        </Box>
      </Box >
    </>
  );
}

export default AssignCoupons;
